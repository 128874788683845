import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { removeSnackbar } from "../redux/snackbars/snackbars.slice";

export const Snackbars = () => {
    const list = useAppSelector((store) => store.snackbars.list);
    const dispatch = useAppDispatch();

    const handleClose = (key: string) => () => {
        dispatch(removeSnackbar(key));
    };

    return (
        <div id="snackbars">
            {Object.keys(list).map((key) => {
                return (
                    <Snackbar
                        key={key}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={true}
                        autoHideDuration={6000}
                        onClose={handleClose(key)}
                    >
                        <Alert onClose={handleClose(key)} severity={list[key].color} sx={{ width: "100%" }}>
                            {list[key].title}
                        </Alert>
                    </Snackbar>
                );
            })}
        </div>
    );
};
