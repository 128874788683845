import { logError } from "./logger";
import axios, {
    AxiosHeaders,
    AxiosRequestConfig,
    AxiosRequestTransformer,
    AxiosResponse,
    AxiosResponseTransformer,
    CancelToken,
    HttpStatusCode,
    Method,
} from "axios";
import { getEndpoint, googleAuthUrl } from "./constants";
import { cleanToken, loadToken, LOCAL_STORAGE_TOKEN_KEY, preSaveToken } from "util/token";
import { waitForLocalStorageValue } from "./helper";

const REQUEST_TIMEOUT = 10000;

let googleRefreshTokenPromise: Promise<any> | null = null;

// MAKE REQUEST

interface ApiRequestInterface {
    baseUrl?: string;
    method?: Method;
    url: string;
    params?: any;
    data?: any;
    transformRequest?: AxiosRequestTransformer[];
    transformResponse?: AxiosResponseTransformer[];
    headers?: AxiosHeaders;
    cancelToken?: CancelToken;
    // accessToken?: string;
    // refreshToken?: string;
    useToken?: boolean;
}

export const apiRequest = <ResponseInterface = any>(
    options: ApiRequestInterface,
    repeatedRequest = false
): Promise<ResponseInterface> => {
    const {
        baseUrl,
        method = "GET",
        url,
        params,
        data,
        transformResponse = [],
        transformRequest = [],
        headers = {},
        cancelToken,
        useToken = true,
        // accessToken,
        // refreshToken,
    } = options;

    const { jwtToken, googleToken, refreshToken } = loadToken();

    if (useToken && (jwtToken || googleToken)) {
        Object.assign(headers, {
            Authorization: `Bearer ${jwtToken || googleToken}`,
        });
    }

    const reqConfig: AxiosRequestConfig<ResponseInterface> = {
        baseURL: baseUrl || getEndpoint(),
        url,
        method,
        headers,
        params,
        data,
        transformRequest: transformRequest.concat(axios.defaults.transformRequest || []),
        transformResponse: transformResponse.concat(axios.defaults.transformResponse || []),
        cancelToken,
        timeout: REQUEST_TIMEOUT,
    };

    return axios
        .request(reqConfig)
        .then((r) => r.data)
        .catch((error) => {
            if (error.response) {
                const response: AxiosResponse = error.response;

                // try to catch 401 > refresh token > resend request
                if (response.status === HttpStatusCode.Unauthorized && !repeatedRequest) {
                    if (refreshToken === "google") {
                        if (googleRefreshTokenPromise === null) {
                            googleRefreshTokenPromise = new Promise((resolve) => {
                                const openedWindow = window.open(googleAuthUrl);
                                if (openedWindow) {
                                    openedWindow.onload = function () {
                                        setTimeout(() => {
                                            // close auth window
                                            openedWindow.close();
                                            resolve(true);
                                        }, 150);

                                        setTimeout(() => {
                                            // wait for all requests
                                            googleRefreshTokenPromise = null;
                                        }, 1000);
                                    };
                                }
                            });
                        }

                        return googleRefreshTokenPromise.then(() => apiRequest(options, true));
                    }

                    const waitForLogin = async () => {
                        try {
                            window.loginDialog();
                            await waitForLocalStorageValue(LOCAL_STORAGE_TOKEN_KEY);
                            return await apiRequest(options, true);
                        } catch {
                            return Promise.reject();
                        }
                    };

                    if (refreshToken) {
                        return refreshTokenRequest(refreshToken)
                            .then(({ accessToken, refreshToken }) => {
                                // update tokens
                                preSaveToken({
                                    jwtToken: accessToken,
                                    refreshToken,
                                });

                                // repeat request
                                return apiRequest(options, true);
                            })
                            .catch(async () => {
                                return waitForLogin().catch(() => Promise.reject(new Error(error.message)));
                            });
                    }

                    return waitForLogin().catch(() => Promise.reject(new Error(error.message)));
                }

                return Promise.reject(new Error(response.data.error || JSON.stringify(response.data)));
            }

            return Promise.reject(new Error(error.message));
        });
};

type RefreshTokenType = Promise<{
    accessToken: string;
    refreshToken: string;
}>;

let refreshTokenRequestPromise: RefreshTokenType | null = null;
const refreshTokenRequest = (refreshToken: string): RefreshTokenType => {
    if (!refreshTokenRequestPromise) {
        refreshTokenRequestPromise = axios
            .post(
                "/organizations/v1/login/refresh",
                {
                    refresh_token: refreshToken,
                },
                {
                    baseURL: getEndpoint(),
                }
            )
            .then((r) => {
                return {
                    accessToken: r.data.token,
                    refreshToken: r.data.refresh_token,
                };
            })
            .catch((e) => {
                // logout
                cleanToken();
                document.location.reload();

                return e;
            })
            .finally(() => {
                setTimeout(() => {
                    refreshTokenRequestPromise = null;
                }, 1000);
            });
    }

    return refreshTokenRequestPromise;
};

// REJECT

export interface ApiRequestRejected<D = any> {
    status?: number;
    data?: D;
    message: string;
}

export function prepareApiThunkReject(error: any): ApiRequestRejected {
    logError(error);

    if (error.response && error.response.data.message) {
        return {
            status: error.response.status,
            data: error.response.data,
            message: error.response.data.message,
        };
    }

    return {
        message: error.message,
    };
}
