import "./types";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./report-web-vitals";
import { Provider } from "react-redux";
import { RootRouter } from "./router";
import { Snackbars } from "./components/snackbars";

import { store } from "./redux/store";
import "./util/constants";
import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <RootRouter />
        <Snackbars />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
