import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import { reducer as authReducer } from "./auth/auth.slice";
import { reducer as snackbarsReducer } from "./snackbars/snackbars.slice";
import { reducer as dashboardReducer } from "./dashboard/dashboard.slice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        snackbars: snackbarsReducer,
        dashboard: dashboardReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
