export const colors = {
    green: {
        aquamarine: "#3EDDA0",
        light: "#C8EAE4",
        magic_mint: "#A4EACF",
        lemon_grass: "#969D8C",
        envy: "#88AC91",
        padua: "#7DBB98",
        medium_aquamarine: "#75CA9E",
        dark_shamrock: "#3cd69b",
        jade_2: "#00c170",
        jade: "#00c568",
    },

    blue: {
        madison: "#062761",
        catskill: "#EFF3F8",
        i2x: "#5272EF",
        i2x_flashy: "#4550E6",
        palatinate_blue: "#3C47E2",
        chadflores: "#EEF3F9",
        hawkes_blue: "#D6E4FE",
        cloudy_blue: "#B8C0CE",
        bay_of_many: "#1C4080",
        rock_blue: "#8D9DB9",
        link_water: "#CDD3DF",
        cadet: "#A6B1C6",
        cadet_blue: "#B5BDCC",
        light_blue: "#DFDFFA",
        egyptian_blue: "#233b9b",
        blue_bell: "#AAB4D8",
        flashymarine: "#062761",
        star: "#A9B4DB",
        loblolly: "#BDC5D3",
        perano: "#B5BBED",
        after_work_blue: "#21276E",
        fresh_air: "#ace5ff",
        lavender_blue_shadow: "#7f89f0",
        light_gray_blue: "#cbd3d9",
    },

    red: {
        copper: "#EF8365",
        sunglo: "#EF6565",
        cupid: "#F4B4BF",
        pale: "#F9D9DE",
        radical_red: "#FF4663",
        light_carmine_pink: "#FF506E",
        froly: "#DA6976",
        old_rose: "#C7747B",
        brandy_rose: "#B68280",
        zorba: "#A59086",
        monza: "#d10013",
        rose: "#E0001F",
    },

    white: {
        basic: "#FFFFFF",
        same: "#EEEEEE",
        smoke: "#F6F6F5",
        albaster: "#FBFBFB",
        lilac: "#E8E8E8",
        black_haze: "#F6F7F7",
        concrete: "#F2F2F3",
        white_smoke: "#F5F5F5",
        cascading_white: "#F6F6F6",
    },

    gray: {
        iron: "#d1d4d8",
        basic: "#808080",
        mercury: "#E6E5E5",
        bright_gray: "#656565",
        monsoon: "#545454",
        mountain_mist: "#969696",
        jumbo: "#888888",
        dark: "#222222",
        mischka: "#CED3DE",
        dove_gray: "#757575",
        light: "#D1D4D6",
        silver_sand: "#BBBBBB",
        wild_sand: "#F5F4F3",
        rock_blue: "#97A3B9",
        solitude: "#E9ECEF",
        light_solitude: "#E9EDEF",
        charcoal: "#4A4A4A",
        athens: "#EDEFF2",
        zircon: "#DFE2E9",
        geyser: "#D6DAE4",
        steel: "#656565",
        night_rider: "#333333",
        chateau: "#A3A3A3",
        hint_of_gray: "#F8F8FA",
        tuatara: "#343434",
        aluminum: "#9A9B9B",
        alto: "#cecece",
        silver_chalice: "#adadad",
        broken_white: "#f2f2f2",
        stock: "#8C8C8C",
        cold_gray: "#9E9E9E",
        icewind_dale: "#e7ebee",
        spanish_gray: "#979797",
        mystic: "#e7ebee",
        kettleman: "#616161",
        noqrei_silver: "#bdbdbd",
        cod_gray: "#1d1d1d",
    },

    yellow: {
        golden_tanoi: "#FEC75D",
        sandy_beach: "#FFEBC7",
        makara: "#695F4D",
        neon_carrot: "#FFA047",
        light: "#FFF0BF",
        star: "#ffaa50",
        glossy_gold: "#ffde72",
        golden_tainoi: "#ffce55",
    },

    black: {
        default: "#000000",
        mine_shaft: "#272727",
        nero: "#222222",
        dark_side_of_the_moon: "#090A0F",
    },
};

export const chartColors = [
    "#3366CC",
    "#DC3912",
    "#FF9900",
    "#109618",
    "#990099",
    "#3B3EAC",
    "#0099C6",
    "#DD4477",
    "#66AA00",
    "#B82E2E",
    "#316395",
    "#994499",
    "#22AA99",
    "#AAAA11",
    "#6633CC",
    "#E67300",
    "#8B0707",
    "#329262",
    "#5574A6",
    "#3B3EAC",
];
