import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { LinkProps } from "@mui/material/Link";
import { LinkBehavior } from "../components/link";

export const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: colors.blue.i2x,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        // secondary: {
        //     light: '#0066ff',
        //     main: '#0044ff',
        //     // dark: will be calculated from palette.secondary.main,
        //     contrastText: '#ffcc00',
        // },
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiMenuItem: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    overflow: "visible",
                },
            },
        },
    },
});
