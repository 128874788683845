import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthStateInterface } from "./auth.interfaces";
import { addAuthLoginReducers } from "./actions/auth.login.action";
import { ROLES } from "../../util/constants";
import {
    cleanToken,
    loadToken,
    LOCAL_STORAGE_TOKEN_KEY,
    selectEmailFromGoogleToken,
    selectUserFromToken,
} from "util/token";
import { addAuthGoogleReducers } from "./actions/auth.google.action";

const emptyState: AuthStateInterface = {
    loading: false,
    userInfo: {
        role: ROLES.GUEST,
        locationID: 0,
        agencyID: 0,
        tenantID: 0,
        userID: 0,
        teamID: 0,
        username: "",
        email: "",
    },
    error: null,
    success: false, // for monitoring the login process.
    displayLoginDialog: false,
};

const loadInitialState = (): AuthStateInterface => {
    const existingAuth = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (existingAuth) {
        const { jwtToken, googleToken } = loadToken();
        if (jwtToken) {
            const userInfo = selectUserFromToken(jwtToken);
            if (userInfo && userInfo.role !== ROLES.GUEST) {
                return {
                    loading: false,
                    userInfo: userInfo,
                    error: null,
                    success: false, // for monitoring the login process.
                    displayLoginDialog: false,
                };
            }
        } else if (googleToken) {
            const email = selectEmailFromGoogleToken(googleToken);
            if (email) {
                return {
                    loading: false,
                    userInfo: {
                        email: email,
                        username: email,
                        role: ROLES.I2X_ADMIN,
                    },
                    error: null,
                    success: false, // for monitoring the login process.
                    displayLoginDialog: false,
                };
            }
        }
    }

    return emptyState;
};

const initialState: AuthStateInterface = loadInitialState();

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            cleanToken();
            state = {
                ...emptyState,
            };
        },
        setDisplayLoginDialog: (state, action: PayloadAction<boolean>) => {
            state.displayLoginDialog = action.payload;
        },
    },
    extraReducers: (builder) => {
        addAuthLoginReducers(builder);
        addAuthGoogleReducers(builder);
    },
});

export const { logout, setDisplayLoginDialog } = authSlice.actions;
export const { reducer } = authSlice;
