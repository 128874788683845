import { SelectOptionInterface } from "../components/forms";

export const ENV = {
    PRODUCTION: "production",
    DEVELOPMENT: "development",
    LOCAL: "local",
};

const CURRENT_ENV = process.env.APP_ENV || ENV.PRODUCTION;
if (Object.values(ENV).includes(CURRENT_ENV)) {
    console.info("ENV: " + CURRENT_ENV);
} else {
    console.error(`ENV: ${CURRENT_ENV} NOT FOUND`);
}

export const isDevelopment = () => {
    return process.env.APP_ENV === ENV.DEVELOPMENT;
};

export const isProduction = () => {
    return process.env.APP_ENV === ENV.PRODUCTION;
};

export const isLocal = () => {
    return process.env.APP_ENV === ENV.LOCAL;
};

/*
 * ENDPOINTS
 */

export const endpoints = {
    [ENV.PRODUCTION]: "https://api.eu.i2x.ai",
    [ENV.DEVELOPMENT]: "https://api-dev.eu.i2x.ai",
    [ENV.LOCAL]: "http://127.0.0.1:3000",
};

export const getEndpoint = () => {
    return endpoints[CURRENT_ENV];
};

export const endpointsDashboard = {
    [ENV.PRODUCTION]: "https://api.eu.i2x.ai",
    [ENV.DEVELOPMENT]: "https://api-dev.eu.i2x.ai",
    [ENV.LOCAL]: "http://127.0.0.1:3000",
};

export const getEndpointDashboard = () => {
    return endpointsDashboard[CURRENT_ENV];
};

export const googleAuthUrl = `${getEndpoint()}/organizations/v1/google_login`;

/*
 * ROLES
 */
export const ROLES = {
    GUEST: "guest",
    I2X_ADMIN: "i2x_admin",
    COMPANY_MANAGER: "company_manager",
    AGENCY_MANAGER: "agency_manager",
    LOCATION_MANAGER: "location_manager",
    VF_TEAM_LEAD: "vf_team_lead",
    CORE_TEAM_LEAD: "core_team_lead",
    CORE_AGENT: "core_agent",
    VF_AGENT: "vf_agent",
};

export const getRolesOptionsList = (): SelectOptionInterface[] =>
    Object.values(ROLES).map((r) => ({
        value: r,
        label: r,
    }));

export const LANG_CODES = ["de-DE", "en-US"];

export const getLangCodesOptionsList = (): SelectOptionInterface[] =>
    LANG_CODES.map((lc) => ({
        value: lc,
        label: lc,
    }));

export const AUTH_SCHEME_EXPLICIT = "explicit";
export const AUTH_SCHEME_IMPLICIT = "implicit";

export const getAuthorizationSchemeOptionsList = (): SelectOptionInterface[] => [
    {
        value: AUTH_SCHEME_EXPLICIT,
        label: AUTH_SCHEME_EXPLICIT,
    },
    {
        value: AUTH_SCHEME_IMPLICIT,
        label: AUTH_SCHEME_IMPLICIT,
    },
];

export const USER_STATE_OPTIONS = [
    { label: "Active 1 Month", value: "active1month" },
    { label: "Active 3 Months", value: "active3month" },
    { label: "Inactive", value: "inactive" },
    { label: "Has Team", value: "hasTeam" },
    { label: "Has No Team", value: "hasNoTeam" },
];
