import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAgentReport, getLocationReport } from "../../pages/dashboard/api";

interface LocationData {
    location: string;
    agency: string;
    tenant: string;
    agents_total: number;
    agents_active_yesterday: number;
    agents_active_last_week: number;
    agents_active_last_month: number;
}

interface AgentData {
    id: number;
    name: string;
    team: string;
    location: string;
    agency: string;
    tenant: string;
    created: string;
    last_login: string | null;
    last_call: string | null;
}

interface ReportState {
    locationData: LocationData[];
    agentData: AgentData[];
    scorecardsData: { [key: string]: number };
    agentActivityPerDay: { [date: string]: number };
    loading: boolean;
    error: string | null;
}

const initialState: ReportState = {
    locationData: [],
    agentData: [],
    scorecardsData: {},
    agentActivityPerDay: {},
    loading: false,
    error: null,
};

export const fetchLocationData = createAsyncThunk("report/fetchLocationData", async () => {
    return await getLocationReport();
});

export const fetchAgentData = createAsyncThunk("report/fetchAgentData", async (agentFilterValue: string) => {
    return await getAgentReport(agentFilterValue);
});

const dashboardSlice = createSlice({
    name: "report",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocationData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLocationData.fulfilled, (state, action) => {
                state.loading = false;
                state.locationData = action.payload.locations;
                state.scorecardsData = action.payload.scorecards;
                state.agentActivityPerDay = action.payload.agent_activity_per_day;
            })
            .addCase(fetchLocationData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch location data";
            })
            .addCase(fetchAgentData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAgentData.fulfilled, (state, action) => {
                state.loading = false;
                state.agentData = action.payload.agents;
            })
            .addCase(fetchAgentData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch agent data";
            });
    },
});

export const { reducer } = dashboardSlice;
