export const enTranslation = {
    nav: {
        profile: "Profile",
        logout: "Logout",
        open_settings: "Open settings",
    },
    common: {
        loading: "Loading",
        cancel: "Cancel",
        confirm: "Confirm",
        loadMore: "Load more",
        loadNext: "Next page",
        loadPrev: "Prev page",
        none: "None",
        search: "Search",
        clear: "Clear",
        info: "Info",
        saved: "Successfully saved",
        save: "Save",
        remove: "Remove",
        clone: "Clone",
        copied: "Copied to clipboard",
        id: "ID",
    },
    login: {
        sign_in: "Sign In",
        sign_in_with_google: "Sign In With Google",
        password: "Password",
        email_address: "Email Address",
        forgot_pass: "Forgot password?",
        recovery: {
            title: "Forgot your password?",
            description:
                "Please enter the email address you used to login. We will send you an email with instructions on how to reset your password.",
            submit: {
                title: "Reset your password",
                in_progress: "Connecting",
            },
            sent_description: "We've sent you an email with a link to reset your password.",
            reset_description: "Set a new password for the account.",
            no_email:
                "If you didn't receive an email: Check if the email is in your spam email box or if the email you entered might be incorrect.",
            password_too_weak: "Password is too weak.",
        },
    },
    errors: {
        unknown_error: "Unknown error",
        invalid_email_or_password: "Invalid email or password",
        invalid_auth_token: "Invalid auth token",
        invalid_google_auth_code: "Invalid Google auth code",
        entity_cannot_be_deleted:
            "Unfortunately, this entity cannot be deleted because the database has data associated with it.",
    },
    dashboard: {
        location_view: "Location View",
        agent_list: "Agent List",
        location: "Location",
        tenant: "Tenant",
        agency: "Agency",
        total_agents: "Total Agents",
        active_agents_yesterday: "Active Agents (Yesterday)",
        active_agents_1_week: "Active Agents (1 Week)",
        active_agents_1_month: "Active Agents (1 month)",
        agent_name: "Agent Name",
        team: "Team",
        created: "Created",
        last_login: "Last Login",
        last_call: "Last Call",
        scorecards: {
            active_agents_last_month: "Active agents last month",
            active_agents_last_week: "Active agents last week",
            active_agents_yesterday: "Active agents yesterday",
            total_agents: "Total agents",
        },
        agent_filters_title: "Agent Filter",
        agent_filters: {
            all: "All",
            active_yesterday: "Active Yesterday",
            active_last_week: "Active Last Week",
            active_last_month: "Active Last Month",
            inactive_yesterday: "Inactive Yesterday",
            inactive_last_week: "Inactive Last Week",
            inactive_last_month: "Inactive Last Month",
        },
    },
};

export type TranslationKeys = typeof enTranslation;
