import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { I2XLogo } from "./logo";
import { useNavigate } from "react-router-dom";
import { colors } from "../styles/colors";
import { t } from "../i18n/translates";
import { useAppSelector } from "../redux/hooks";
import { selectUserInfo } from "../redux/auth/auth.selectors";

interface MenuLink {
    label: string;
    to: string;
    icon?: React.ReactElement;
}

const pages: MenuLink[] = [];

const pagesWithIcon: MenuLink[] = [];

const settings: MenuLink[] = [
    // {label: t('nav.profile'), to: "/profile"},
    {
        label: t("nav.logout"),
        to: "/logout",
    },
];

export function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const navigate = useNavigate();
    const userInfo = useAppSelector(selectUserInfo);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleNavMenu = () => () => {
        setAnchorElNav(null);
    };

    const handleUserMenu = () => () => {
        setAnchorElUser(null);
    };

    const handleLogo = () => {
        navigate("/");
    };

    return (
        <AppBar position="static" sx={{ zIndex: 101, position: "relative" }}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Typography
                        noWrap
                        component="div"
                        onClick={handleLogo}
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            cursor: "pointer",
                        }}
                    >
                        <I2XLogo color={colors.white.basic} />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleNavMenu()}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.label} href={page.to}>
                                    <Typography textAlign="center">{page.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Typography
                        noWrap
                        component="div"
                        onClick={handleLogo}
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            cursor: "pointer",
                        }}
                    >
                        <I2XLogo color={colors.white.basic} />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <Button key={page.label} href={page.to} sx={{ my: 2, color: "white", display: "block" }}>
                                {page.label}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0, ml: 2 }}>
                        {pagesWithIcon.map((page) => (
                            <Tooltip key={page.label} title={page.label}>
                                <IconButton href={page.to} sx={{ p: 0 }}>
                                    <Avatar sx={{ color: colors.white.basic, bgcolor: colors.blue.i2x }}>
                                        {page.icon}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        ))}

                        <Tooltip title={t("nav.open_settings")}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 2 }}>
                                <Avatar sx={{ bgcolor: colors.white.basic, color: colors.blue.i2x }}>
                                    {userInfo.username.slice(0, 1).toUpperCase()}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleUserMenu()}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.label} onClick={() => navigate(setting.to)}>
                                    <Typography textAlign="center">{setting.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
