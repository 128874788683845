import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthStateInterface, UserInfoInterface } from "redux/auth/auth.interfaces";
import { preSaveToken } from "util/token";
import { AUTH_LOGIN_GOOGLE } from "../auth.actions.types";
import { apiRequest, ApiRequestRejected } from "../../../util/api";
import { logError } from "util/logger";
import { t } from "i18n/translates";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";

interface LoginGooglePayloadInterface {
    userInfo: UserInfoInterface;
    googleToken: string;
}

export const loginGoogleUser = createAsyncThunk<
    LoginGooglePayloadInterface,
    string,
    {
        rejectValue: ApiRequestRejected;
    }
>(AUTH_LOGIN_GOOGLE, async (code, thunkApi) => {
    try {
        const user = await apiRequest({
            method: "GET",
            url: `/organizations/v1/google_callback`,
            params: { code },
        });

        const { role, email, token } = user;

        const userInfo: UserInfoInterface = {
            email: email,
            username: email,
            role: role,
        };
        if (!userInfo) {
            throw new Error(t("errors.invalid_google_auth_code"));
        }

        return {
            userInfo: userInfo,
            googleToken: token,
        };
    } catch (error: any) {
        if (error.response && error.response.status) {
            return thunkApi.rejectWithValue({
                message: t("errors.invalid_google_auth_code"),
            });
        }

        logError(error);

        return thunkApi.rejectWithValue({
            message: error.message,
        });
    }
});

export function addAuthGoogleReducers(builder: ActionReducerMapBuilder<AuthStateInterface>) {
    // auth.login.action.ts
    builder.addCase(loginGoogleUser.pending, (state, action) => {
        state.loading = true;
    });
    builder.addCase(loginGoogleUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.userInfo = payload.userInfo;

        preSaveToken({
            googleToken: payload.googleToken,
            refreshToken: "google",
        });
    });
    builder.addCase(loginGoogleUser.rejected, (state, action) => {
        if (action.payload) {
            state.error = action.payload.message || null;
        } else {
            state.error = action.error.message || null;
        }
        state.loading = false;
        state.success = false;
    });
}
