import React, { useState } from "react";
import { useQuery } from "../../util/hooks";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../styles/theme";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { I2XLogo } from "../../components/logo";
import TextField from "@mui/material/TextField";
import { t } from "../../i18n/translates";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { apiRequest } from "../../util/api";
import { useNavigate } from "react-router-dom";
import zxcvbn from "zxcvbn";

const REQUIRED_PASSWORD_SCORE = 3;

const resetLinkRequest = (email: string) =>
    apiRequest({
        method: "POST",
        url: `/organizations/v1/login/recovery`,
        data: { email, requester: "app" },
    });

const resetPasswordRequest = (token: string, password: string) =>
    apiRequest({
        method: "POST",
        url: `/organizations/v1/login/reset`,
        data: { token, password },
    });

export const PasswordRecovery = () => {
    const query = useQuery();

    const token = query.get("token");
    if (token) {
        return <RecoveryResetPassword token={token} />;
    }

    return <RecoveryRequestLink />;
};

interface RecoveryResetPasswordFormInterface {
    password: string;
}

const RecoveryResetPassword = ({ token }: { token: string }) => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<RecoveryResetPasswordFormInterface>({
        values: {
            password: "",
        },
    });

    const nav = useNavigate();

    const [loading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<RecoveryResetPasswordFormInterface> = async (data) => {
        setLoading(true);
        resetPasswordRequest(token, data.password)
            .then(() => {
                nav("/login");
            })
            .catch((error) => {
                setError("password", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const validatePassword = (pass: string): string | undefined => {
        const passStrength = zxcvbn(pass);
        if (passStrength.score < REQUIRED_PASSWORD_SCORE) {
            return t("login.recovery.password_too_weak");
        }

        return undefined;
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <I2XLogo />
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: "100%" }}>
                        <React.Fragment>
                            <Typography sx={{ mt: 3 }}>{t("login.recovery.reset_description")}</Typography>
                            <Controller
                                name={"password"}
                                control={control}
                                rules={{
                                    required: true,
                                    validate: validatePassword,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="outlined-required"
                                        label={t("login.password")}
                                        sx={{ mb: 2 }}
                                        margin="normal"
                                        type="password"
                                        required
                                        fullWidth
                                        autoComplete="email"
                                        autoFocus
                                        error={!!errors.password}
                                        helperText={errors.password?.message}
                                    />
                                )}
                            />
                            <Button type="submit" fullWidth variant="contained" disabled={loading} sx={{ mb: 2 }}>
                                {loading ? (
                                    <span>{t("login.recovery.submit.in_progress")}</span>
                                ) : (
                                    <span>{t("login.recovery.submit.title")}</span>
                                )}
                            </Button>
                        </React.Fragment>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

interface RecoveryRequestLinkFormInterface {
    email: string;
}
const RecoveryRequestLink = () => {
    const { control, handleSubmit, reset, setError } = useForm<RecoveryRequestLinkFormInterface>({
        values: {
            email: "",
        },
    });

    const [passwordRequestSent, setPasswordRequestSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<RecoveryRequestLinkFormInterface> = async (data) => {
        setLoading(true);
        resetLinkRequest(data.email)
            .then(() => {
                reset();
                setPasswordRequestSent(true);
            })
            .catch((error) => {
                setError("email", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <I2XLogo />
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: "100%" }}>
                        {passwordRequestSent ? (
                            <React.Fragment>
                                <Typography
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    {t("login.recovery.sent_description")}
                                </Typography>
                                <Typography sx={{ fontSize: "0.9em" }}>{t("login.recovery.no_email")}</Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography sx={{ mb: 2, mt: 2 }}>{t("login.recovery.description")}</Typography>
                                <Controller
                                    name={"email"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id="outlined-required"
                                            label={t("login.email_address")}
                                            sx={{ mb: 2 }}
                                            margin="normal"
                                            type="email"
                                            required
                                            fullWidth
                                            autoComplete="email"
                                            autoFocus
                                        />
                                    )}
                                />
                                <Button type="submit" fullWidth variant="contained" disabled={loading} sx={{ mb: 2 }}>
                                    {loading ? (
                                        <span>{t("login.recovery.submit.in_progress")}</span>
                                    ) : (
                                        <span>{t("login.recovery.submit.title")}</span>
                                    )}
                                </Button>
                            </React.Fragment>
                        )}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};
