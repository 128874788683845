import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Snackbar, SnackbarsStateInterface } from "./snackbars.interfaces";
import { generateUniqueKey } from "../../util/password";

const emptyState: SnackbarsStateInterface = {
    list: {},
};

const loadInitialState = (): SnackbarsStateInterface => {
    return emptyState;
};

const initialState: SnackbarsStateInterface = loadInitialState();

const snackbarsSlice = createSlice({
    name: "snackbars",
    initialState,
    reducers: {
        addSnackbar: (state, action: PayloadAction<Partial<Snackbar>>) => {
            const snackbar: Snackbar = {
                key: generateUniqueKey(),
                color: "success",
                title: "",
                ...action.payload,
            };
            state.list[snackbar.key] = snackbar;
        },
        removeSnackbar: (state, action: PayloadAction<string>) => {
            delete state.list[action.payload];
        },
    },
});

export const { addSnackbar, removeSnackbar } = snackbarsSlice.actions;
export const { reducer } = snackbarsSlice;
