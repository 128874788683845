import { RootState } from "../store";
import { ROLES } from "util/constants";
import { UserInfoInterface } from "./auth.interfaces";

export const selectAuthRole = (state: RootState): string => {
    return state.auth.userInfo.role;
};

export const selectIsGuest = (state: RootState): boolean => {
    return state.auth.userInfo.role === ROLES.GUEST;
};

export const selectUserInfo = (state: RootState): UserInfoInterface => {
    return state.auth.userInfo;
};
