import { get } from "lodash-es";
import { enTranslation } from "./locales/en";
import { deTranslation } from "./locales/de";

export function getBrowserLanguage(
    defaultLanguage: string = 'en'
): string {
    const browserLang = navigator.language || navigator.languages[0];

    if (!browserLang) {
        return defaultLanguage;
    }

    return browserLang.slice(0, 2).toLowerCase();
}

const translates = getBrowserLanguage() === 'de' ? deTranslation : enTranslation;

type DotPrefix<T extends string> = T extends "" ? "" : `.${T}`;

type DotNestedKeys<T> = (
    T extends object
        ? {
              [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}`;
          }[Exclude<keyof T, symbol>]
        : ""
) extends infer D
    ? Extract<D, string>
    : never;

export const t = (key: DotNestedKeys<typeof translates>, params: { [key: string]: string | number } = {}): string => {
    return Object.keys(params).reduce((current, paramKey) => {
        return current.replaceAll(`{{${paramKey}}}`, `${params[paramKey]}`);
    }, get(translates, key));
};
