import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ROLES } from "./util/constants";
import { Page } from "./pages/page";
import { Error404 } from "./components/404";
import { Logout } from "./pages/login/logout";
import { LoginPage } from "./pages/login/login";
import DashboardPage from "./pages/dashboard/dashboard";
import { PasswordRecovery } from "./pages/login/recovery";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <Page roles={[ROLES.COMPANY_MANAGER]}>
                <DashboardPage />
            </Page>
        ),
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/recovery",
        element: <PasswordRecovery />,
    },
    {
        path: "/logout",
        element: <Logout />,
    },
    {
        path: "*",
        element: <Error404 />,
    },
]);

export function RootRouter() {
    return <RouterProvider router={router} />;
}
