import { get, isEmpty } from "lodash-es";
import { Base64 } from "js-base64";
import { UserInfoInterface } from "../redux/auth/auth.interfaces";
import { ROLES } from "./constants";

export const LOCAL_STORAGE_TOKEN_KEY = "auth";

interface PreSavedTokensInterface {
    jwtToken?: string;
    refreshToken?: string;
    googleToken?: string;
}

export const preSaveToken = (tokens: PreSavedTokensInterface) => {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(tokens));
};

export const loadToken = (): PreSavedTokensInterface => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || "{}");
};

export const cleanToken = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
};

const decodeJWT = (jwt: string) => {
    if (!jwt) {
        return {};
    }

    try {
        if (jwt.split(" ").length > 1) {
            return {};
        }
        const base64 = jwt.split(".")[1].replace("-", "+").replace("_", "/");
        return JSON.parse(Base64.decode(base64));
    } catch (_) {
        return {};
    }
};

export const selectUserFromToken = (token: string): UserInfoInterface | null => {
    const decoded = decodeJWT(token);

    if (isEmpty(decoded)) {
        return null;
    }

    const orgs = get(decoded, "orgs", {});
    const role = get(decoded, "resource_access.web.roles[0]", ROLES.GUEST);

    return {
        email: decoded.email,
        username: decoded.username,
        role,
        locationID: orgs.location_id,
        agencyID: orgs.agency_id,
        tenantID: orgs.company_id,
        userID: decoded.user_id,
        teamID: decoded.team_id,
    };
};

export const selectEmailFromGoogleToken = (token: string): string | null => {
    const decoded = decodeJWT(token);

    if (isEmpty(decoded)) {
        return null;
    }

    return decoded.email;
};

const secondInMilliseconds = 1000;
export const getTokenValidity = (token: string) => {
    const decoded = decodeJWT(token);

    if (isEmpty(decoded)) {
        return false;
    }

    const diff = Math.floor(decoded.exp - Date.now() / secondInMilliseconds);
    return diff > 0;
};
