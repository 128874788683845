import { useState } from "react";
import { Box } from "@mui/material";

interface DataPoint {
    date: string;
    agents: number;
}

interface TooltipData extends DataPoint {
    x: number;
    y: number;
}

export const LineChart: React.FC<{ data: DataPoint[] }> = ({ data }) => {
    const width = 300;
    const height = 150;
    const padding = 30;

    const [tooltip, setTooltip] = useState<TooltipData | null>(null);

    if (!data || data.length === 0) {
        return null;
    }

    const maxAgents = Math.max(...data.map((d) => d.agents));
    const points = data.map((d, i) => {
        const x = padding + (i * (width - 2 * padding)) / (data.length - 1);
        const y = height - padding - (d.agents / maxAgents) * (height - 2 * padding);
        return { ...d, x, y };
    });

    const linePoints = points.map((p) => `${p.x},${p.y}`).join(" ");

    return (
        <Box sx={{ position: "relative" }}>
            <svg width={width} height={height}>
                {/* Y Axis */}
                <line x1={padding} y1={padding} x2={padding} y2={height - padding} stroke="#999" />
                {/* X Axis */}
                <line x1={padding} y1={height - padding} x2={width - padding} y2={height - padding} stroke="#999" />

                {/* Y Axis Labels */}
                {[0, Math.round(maxAgents / 2), maxAgents].map((val, idx) => (
                    <text
                        key={idx}
                        x={padding - 8}
                        y={height - padding - (val / maxAgents) * (height - 2 * padding) + 4}
                        fontSize={10}
                        textAnchor="end"
                    >
                        {val}
                    </text>
                ))}

                {/* X Axis Labels - Only first and last date */}
                {[points[0], points[points.length - 1]].map((p, idx) => (
                    <text
                        key={idx}
                        x={p.x}
                        y={height - padding + 15}
                        fontSize={10}
                        textAnchor={idx === 0 ? "start" : "end"}
                    >
                        {p.date}
                    </text>
                ))}

                {/* Line */}
                <polyline points={linePoints} fill="none" stroke="#1976d2" strokeWidth={2} />

                {/* Data Points */}
                {points.map((p, idx) => (
                    <circle
                        key={idx}
                        cx={p.x}
                        cy={p.y}
                        r={4}
                        fill="#1976d2"
                        onMouseEnter={() => setTooltip(p)}
                        onMouseLeave={() => setTooltip(null)}
                        style={{ cursor: "pointer" }}
                    />
                ))}
            </svg>

            {/* Tooltip */}
            {tooltip && (
                <Box
                    sx={{
                        position: "absolute",
                        left: tooltip.x + 10,
                        top: tooltip.y - 35,
                        bgcolor: "rgba(0,0,0,0.75)",
                        color: "#fff",
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: 16,
                        pointerEvents: "none",
                        whiteSpace: "nowrap",
                    }}
                >
                    {tooltip.date}: <b>{tooltip.agents}</b>
                </Box>
            )}
        </Box>
    );
};
