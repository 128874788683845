import { TranslationKeys } from "./en";

export const deTranslation: TranslationKeys = {
    nav: {
        profile: "Profil",
        logout: "Abmelden",
        open_settings: "Einstellungen öffnen",
    },
    common: {
        loading: "Laden",
        cancel: "Abbrechen",
        confirm: "Bestätigen",
        loadMore: "Mehr laden",
        loadNext: "Nächste Seite",
        loadPrev: "Vorherige Seite",
        none: "Keine",
        search: "Suchen",
        clear: "Löschen",
        info: "Info",
        saved: "Erfolgreich gespeichert",
        save: "Speichern",
        remove: "Entfernen",
        clone: "Klonen",
        copied: "In die Zwischenablage kopiert",
        id: "ID",
    },
    login: {
        sign_in: "Anmelden",
        sign_in_with_google: "Mit Google anmelden",
        password: "Passwort",
        email_address: "E-Mail-Adresse",
        forgot_pass: "Passwort vergessen?",
        recovery: {
            title: "Passwort vergessen?",
            description:
                "Bitte gib die E-Mail-Adresse ein, mit der du dich anmeldest. Wir senden dir eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts.",
            submit: {
                title: "Passwort zurücksetzen",
                in_progress: "Verbindung wird hergestellt",
            },
            sent_description: "Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen deines Passworts geschickt.",
            reset_description: "Lege ein neues Passwort für dein Konto fest.",
            no_email:
                "Falls du keine E-Mail erhalten hast: Prüfe, ob die E-Mail in deinem Spam-Ordner gelandet ist oder ob die eingegebene E-Mail-Adresse eventuell falsch war.",
            password_too_weak: "Passwort ist zu schwach.",
        },
    },
    errors: {
        unknown_error: "Unbekannter Fehler",
        invalid_email_or_password: "Ungültige E-Mail oder Passwort",
        invalid_auth_token: "Ungültiger Authentifizierungstoken",
        invalid_google_auth_code: "Ungültiger Google-Authentifizierungscode",
        entity_cannot_be_deleted:
            "Dieses Element kann leider nicht gelöscht werden, da zugehörige Daten in der Datenbank existieren.",
    },
    dashboard: {
        location_view: "Standortansicht",
        agent_list: "Agentenliste",
        location: "Standort",
        tenant: "Mandant",
        agency: "Agentur",
        total_agents: "Agenten gesamt",
        active_agents_yesterday: "Aktive Agenten (Gestern)",
        active_agents_1_week: "Aktive Agenten (1 Woche)",
        active_agents_1_month: "Aktive Agenten (1 Monat)",
        agent_name: "Agentenname",
        team: "Team",
        created: "Erstellt",
        last_login: "Letzte Anmeldung",
        last_call: "Letzter Anruf",
        scorecards: {
            active_agents_last_month: "Aktive Agenten im letzten Monat",
            active_agents_last_week: "Aktive Agenten in der letzten Woche",
            active_agents_yesterday: "Aktive Agenten gestern",
            total_agents: "Agenten insgesamt",
        },
        agent_filters_title: "Agentenfilter",
        agent_filters: {
            all: "Alle",
            active_yesterday: "Aktiv gestern",
            active_last_week: "Aktiv letzte Woche",
            active_last_month: "Aktiv letzten Monat",
            inactive_yesterday: "Inaktiv gestern",
            inactive_last_week: "Inaktiv letzte Woche",
            inactive_last_month: "Inaktiv letzten Monat",
        },
    },
};
