import { SelectOptionInterface } from "../components/forms";
import { ROLES } from "./constants";
import { v4 as uuidv4 } from "uuid";

export const uuid = () => uuidv4();

export const selectOption = (value: string, options: SelectOptionInterface[]): string | JSX.Element => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : value || "None";
};

export const isAgentRole = (role: string) => {
    return ROLES.CORE_AGENT === role || ROLES.VF_AGENT === role;
};

export const isTeamLeadRole = (role: string) => {
    return ROLES.VF_TEAM_LEAD === role || ROLES.CORE_TEAM_LEAD === role;
};

export const saveToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const loadFromLocalStorage = (key: string) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};

export async function waitForLocalStorageValue(key: string, timeout = 180000): Promise<string> {
    const startTime = Date.now();

    return new Promise((resolve, reject) => {
        const checkValue = () => {
            const value = localStorage.getItem(key);
            if (value !== null) {
                resolve(value);
            } else if (Date.now() - startTime >= timeout) {
                reject(new Error(`timeout: value for key "${key}" not found`));
            } else {
                setTimeout(checkValue, 1000); // Check every second
            }
        };

        checkValue();
    });
}
