import { apiRequest } from "../../util/api";
import { getEndpointDashboard } from "../../util/constants";

export const getLocationReport = () =>
    apiRequest({
        baseUrl: getEndpointDashboard(),
        url: "/dashboard/cross_report/location",
        method: "GET",
    });

export const getAgentReport = (agentFilterValue = "") =>
    apiRequest({
        baseUrl: getEndpointDashboard(),
        url: `/dashboard/cross_report/agent?${agentFilterValue ? `${agentFilterValue}=1` : ""}`,
        method: "GET",
    });
