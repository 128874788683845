import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthStateInterface, UserInfoInterface } from "redux/auth/auth.interfaces";
import { preSaveToken, selectUserFromToken } from "util/token";
import { AUTH_LOGIN } from "../auth.actions.types";
import { apiRequest, ApiRequestRejected } from "../../../util/api";
import { logError } from "util/logger";
import { t } from "i18n/translates";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";

interface LoginParamsInterface {
    email: string;
    password: string;
}

interface LoginPayloadInterface {
    userInfo: UserInfoInterface;
    jwtToken: string;
    refreshToken: string;
}

export const loginUser = createAsyncThunk<
    LoginPayloadInterface,
    LoginParamsInterface,
    {
        rejectValue: ApiRequestRejected;
    }
>(AUTH_LOGIN, async ({ email, password }, thunkApi) => {
    try {
        const user = await apiRequest({
            method: "POST",
            url: `/organizations/v1/login`,
            data: { email, password },
        });

        const { refresh_token, token } = user;

        const userInfo = selectUserFromToken(token);
        if (!userInfo) {
            throw new Error(t("errors.invalid_auth_token"));
        }

        return {
            userInfo: userInfo,
            jwtToken: token,
            refreshToken: refresh_token,
        };
    } catch (error: any) {
        if (error.response && error.response.status) {
            return thunkApi.rejectWithValue({
                message: t("errors.invalid_email_or_password"),
            });
        }

        logError(error);

        return thunkApi.rejectWithValue({
            message: error.message,
        });
    }
});

export function addAuthLoginReducers(builder: ActionReducerMapBuilder<AuthStateInterface>) {
    // auth.login.action.ts
    builder.addCase(loginUser.pending, (state, action) => {
        state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.displayLoginDialog = false;
        state.userInfo = payload.userInfo;

        preSaveToken({
            jwtToken: payload.jwtToken,
            refreshToken: payload.refreshToken,
        });
    });
    builder.addCase(loginUser.rejected, (state, action) => {
        if (action.payload) {
            state.error = action.payload.message || null;
        } else {
            state.error = action.error.message || null;
        }
        state.loading = false;
        state.success = false;
    });
}
