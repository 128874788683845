import { useAppSelector } from "../redux/hooks";
import { selectIsGuest } from "../redux/auth/auth.selectors";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../styles/theme";
import { Navbar } from "./navbar";
import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../styles/colors";

export const Error404 = () => {
    const isGuest = useAppSelector(selectIsGuest);

    return (
        <ThemeProvider theme={theme}>
            {!isGuest && <Navbar />}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    mt: 3,
                    mb: 3,
                }}
            >
                <Typography variant="h1" style={{ color: colors.blue.i2x }}>
                    404
                </Typography>
                <Typography variant="h6" style={{ color: colors.black.dark_side_of_the_moon }}>
                    The page you’re looking for doesn’t exist.
                </Typography>
            </Box>
        </ThemeProvider>
    );
};
