import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "styles/theme";
import { Navbar } from "../../components/navbar";
import { Card, CardContent, Container, Box, Typography, Tabs, Tab, MenuItem, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { t } from "i18n/translates";
import { fetchAgentData, fetchLocationData } from "redux/dashboard/dashboard.slice";
import dayjs from "dayjs";
import { LineChart } from "./linar.chart";
import { formatDateTime } from "../../util/time";

export const USER_STATE_OPTIONS = [
    { label: t("dashboard.agent_filters.all"), value: "all" },
    { label: t("dashboard.agent_filters.active_yesterday"), value: "active_yesterday" },
    { label: t("dashboard.agent_filters.active_last_week"), value: "active_last_week" },
    { label: t("dashboard.agent_filters.active_last_month"), value: "active_last_month" },
    { label: t("dashboard.agent_filters.inactive_yesterday"), value: "inactive_yesterday" },
    { label: t("dashboard.agent_filters.inactive_last_week"), value: "inactive_last_week" },
    { label: t("dashboard.agent_filters.inactive_last_month"), value: "inactive_last_month" },
];

function DashboardContent() {
    const dispatch = useAppDispatch();
    const { locationData, agentActivityPerDay, agentData, scorecardsData, loading } = useAppSelector(
        (state) => state.dashboard
    );

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [agentFilterValue, setAgentFilterValue] = React.useState("all");

    useEffect(() => {
        dispatch(fetchLocationData());
        dispatch(fetchAgentData(agentFilterValue));
    }, [dispatch, agentFilterValue]);

    const statsCards = [
        "active_agents_last_month",
        "active_agents_last_week",
        "active_agents_yesterday",
        "total_agents",
    ];

    const a11yProps = (index: number) => ({
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
        style: {
            fontSize: "16px",
            fontWeight: "bold",
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Navbar />
            <Container maxWidth={false} sx={{ pb: 3 }}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        gap: 2,
                        mt: 3,
                    }}
                >
                    <Card>
                        <CardContent
                            style={{
                                height: "100%",
                                padding: 0,
                            }}
                        >
                            <LineChart
                                data={Object.keys(agentActivityPerDay).map((d) => ({
                                    date: dayjs(d).format("DD.MM"),
                                    agents: agentActivityPerDay[d],
                                }))}
                            />
                        </CardContent>
                    </Card>

                    {statsCards.map((cardKey) => (
                        <Card
                            key={cardKey}
                            sx={{
                                flexGrow: "1",
                            }}
                        >
                            <CardContent
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                    }}
                                >
                                    {t(`dashboard.scorecards.${cardKey}` as any)}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "46px",
                                        fontWeight: "600",
                                    }}
                                >
                                    {(scorecardsData && scorecardsData[cardKey]) || 0}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>

                <Box sx={{ mt: 3, mb: 1, display: "flex", justifyContent: "space-between" }}>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={(event: React.SyntheticEvent, newValue: number) => {
                            setSelectedTabIndex(newValue);
                        }}
                        aria-label="basic tabs example"
                    >
                        <Tab label={t("dashboard.location_view")} {...a11yProps(0)} />
                        <Tab label={t("dashboard.agent_list")} {...a11yProps(1)} />
                    </Tabs>

                    {selectedTabIndex === 1 && (
                        <div>
                            <TextField
                                select
                                label={t(`dashboard.agent_filters_title`)}
                                onChange={(e) => {
                                    setAgentFilterValue(e.target.value);
                                }}
                                value={agentFilterValue}
                                sx={{
                                    minWidth: 200,
                                }}
                                size={"small"}
                            >
                                {USER_STATE_OPTIONS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    )}
                </Box>
                {selectedTabIndex === 0 && (
                    <DataGrid
                        disableRowSelectionOnClick
                        rows={locationData}
                        columns={[
                            { field: "location", headerName: t("dashboard.location"), flex: 1 },
                            { field: "tenant", headerName: t("dashboard.tenant"), flex: 1 },
                            { field: "agency", headerName: t("dashboard.agency"), flex: 1 },
                            { field: "agents_total", headerName: t("dashboard.total_agents"), flex: 1 },
                            {
                                field: "active_agents_yesterday",
                                headerName: t("dashboard.active_agents_yesterday"),
                                flex: 1,
                            },
                            {
                                field: "active_agents_last_week",
                                headerName: t("dashboard.active_agents_1_week"),
                                flex: 1,
                            },
                            {
                                field: "active_agents_last_month",
                                headerName: t("dashboard.active_agents_1_month"),
                                flex: 1,
                            },
                        ]}
                        loading={loading}
                        getRowId={(row) => `${row.location}-${row.agency}-${row.tenant}`}
                    />
                )}
                {selectedTabIndex === 1 && (
                    <DataGrid
                        disableRowSelectionOnClick
                        rows={agentData}
                        columns={[
                            { field: "name", headerName: t("dashboard.agent_name"), flex: 1.2 },
                            { field: "team", headerName: t("dashboard.team"), flex: 1 },
                            { field: "location", headerName: t("dashboard.location"), flex: 1 },
                            { field: "agency", headerName: t("dashboard.agency"), flex: 1 },
                            { field: "tenant", headerName: t("dashboard.tenant"), flex: 1 },
                            {
                                field: "created",
                                headerName: t("dashboard.created"),
                                flex: 1,
                                valueFormatter: (value) => (value ? formatDateTime(value) : "-"),
                            },
                            {
                                field: "last_login",
                                headerName: t("dashboard.last_login"),
                                flex: 1,
                                valueFormatter: (value) => (value ? formatDateTime(value) : "-"),
                            },
                            {
                                field: "last_call",
                                headerName: t("dashboard.last_call"),
                                flex: 1,
                                valueFormatter: (value) => (value ? formatDateTime(value) : "-"),
                            },
                        ]}
                        loading={loading}
                        getRowId={(row) => row.id}
                    />
                )}
            </Container>
        </ThemeProvider>
    );
}

export default function DashboardPage() {
    return <DashboardContent />;
}
