import { useAppSelector } from "../redux/hooks";
import { selectAuthRole } from "../redux/auth/auth.selectors";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ROLES } from "../util/constants";
import { Error403 } from "../components/403";
import { LoginDialog } from "./login/login";

interface PageProps {
    roles: Array<string>;
    children: JSX.Element;
}

export const Page = ({ children, roles }: PageProps) => {
    const navigate = useNavigate();
    const role = useAppSelector(selectAuthRole);

    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        if (role === ROLES.GUEST) {
            navigate("/login");
        }
        if (!roles.includes(role)) {
            setRestricted(true);
        }
    }, [role, navigate, roles]);

    if (restricted) {
        return <Error403 />;
    }

    return (
        <>
            <LoginDialog />
            {children}
        </>
    );
};
